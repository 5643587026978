.App {
  font-family: sans-serif;
  text-align: center;
}

html, body, #root {
  min-height: 100%;
}

#root {
  background-color: rgb(245, 245, 245);
}

body {
  margin: 0;
}

#login-panel-wrapper .MuiGrid-container:first-of-type {
  margin-top: 0.5em;
}

#main-content-wrapper > div, #main-content-wrapper .MuiBox-root > .MuiContainer-root > .MuiPaper-root, .default-paginator > div:nth-of-type(2) {
  display: flex;
  width: 100%;
}

.MuiOutlinedInput-input {
  padding: 14px 12px;
}

.default-paginator > div:nth-of-type(2) {
  display: flex;
}

.MuiListItem-container:not(.single-color-list):nth-of-type(2n) {
  background-color: rgb(250, 250, 250);
}

.base-product-variant-list .MuiListItem-container:nth-of-type(1) {
  display: none;
}

.MuiAlert-icon {
  display: flex;
  align-items: center;
}

[data-testid="default-collection-list"] {
  width: 100%;
}

[data-testid$="-paginator-page"] {
  font-size: unset !important;
}

.MuiTableCell-head:last-of-type {
  display: none;
}

@media screen and (min-width: 960px) {
  .MuiDialog-paper {
    min-width: 576px;
  }
}

div[role=presentation] > .MuiContainer-maxWidthSm {
  height: 100%;
  display: flex;
  align-items: center;
}

div[role=presentation] > .MuiContainer-maxWidthSm > .MuiPaper-root {
  height: auto !important;
}

@media screen and (max-width: 800px) {
  .MuiTabs-fixed {
    overflow: scroll !important;
  }
  .MuiGrid-root.MuiGrid-container {
    padding-right: 0 !important;
  }
}

[data-testid="search-bar-wrapper"] > .MuiBox-root > .MuiBox-root > form, [data-testid="search-bar-wrapper"] > form {
  width: 100% !important;
}

.default-paginator > div:nth-of-type(2) {
  justify-content: center;
}

#dashboard-baskets [data-testid="basket-filterer-wrapper"] {
  display: none !important;
}

li[data-testid^="basket-product-list-item"]:nth-of-type(2n+1) {
  background-color: rgb(245, 245, 245);
}

[data-testid="user-remember-input"] {
  display: none !important;
}